import Model from "./Model";
import Mailing from "./Mailing";
export default class Responder extends Model {
  resource() {
    return "responders";
  }
  relations() {
    return {
      mailings: Mailing,
    };
  }
}
